import { uploadPhoto } from "@/api/UploadApi";
import Immobilie from "@/models/immobilie.model";
import CachedPhoto from "@/models/photo/cached-photo.model";

export default function useImmobilieHelper(immobilie: Immobilie) {
  function getVerwalterLabel() {
    if (!immobilie?.verwalters || immobilie?.verwalters?.length === 0) return;
    return immobilie.verwalters[0].organisation;
  }

  async function uploadVorschaubild(
    photo: CachedPhoto,
    propertyId: any
  ) {
    const result = await uploadPhoto(
      photo,
      "api::immobilie.immobilie",
      propertyId?.toString(),
      "vorschaubild",
      propertyId + Date.now().toString() + ".jpeg",
      "immobilie"
    );

    // fotoID auslesen
    const res = await Immobilie.api().post("/immobilie/addImmoPhoto", {
      propertyId: propertyId?.toString(),
      imageId: result.data[0].id,
    });
  }

  async function uploadImmoBilder(
    photos: CachedPhoto[],
    propertyId: any
  ) {
    console.log("uploadImmoBilder start...");

    const results = await Promise.all(
      photos.map((el) => {
        return uploadPhoto(
          el,
          "api::immobilie.immobilie",
          propertyId?.toString(),
          "bilder",
          propertyId + Date.now().toString() + ".jpeg",
          "immobilie"
        );
      })
    );
  }

  return {
    getVerwalterLabel,
    uploadVorschaubild,
    uploadImmoBilder,
  };
}
