<template> 
  <ion-item
    :class="{ 'selected-item': selected }"
    @click="open"
  >
    <ion-label
      class="p-2 md:p-4"
      :class="{ 'font-bold': selected }"
    >
      {{ property.externeObjektNr }}
    </ion-label>
    <div
      v-if="featureFlags?.property?.changeStatus"
      class="flex cursor-pointer p-4"
      :title="$t('immobilie.updateStatus')"  
      @click="$event.stopPropagation(); changeStatus(property, t)"
    >
      <ion-icon
        class="w-5 h-5 sm:w-8 sm:h-8"
        :icon="statusIconState"
      />
    </div>
    <div
      v-if="featureFlags?.property?.changePerson"
      class="flex personIcon cursor-pointer w-5 h-5 text-xs sm:w-8 sm:h-8 sm:text-base"
      :title="$t('immobilie.updatePerson')"
      @click="$event.stopPropagation(), changePerson(property, t)"
    >
      <div>
        {{ username }}
      </div>
    </div>
    <ion-button
      fill="clear"
      class="mr-0 sm:mr-1"
      @click.stop="openPropertyModal"
    >
      <ion-icon
        class="w-5 h-5 sm:w-8 sm:h-8"
        :title="capitalizeString(t('immobilie.oeffnen'))"
        :icon="chevronForwardOutline"
      />
    </ion-button>
  </ion-item>
</template>
<script lang="ts" setup>
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { ImmobilieStatus } from "@/models/immobilie/interfaces/IImmobilie";
import { capitalizeString } from "@/utilities/helper";
import { IonButton, IonIcon, IonItem, IonLabel, useIonRouter } from "@ionic/vue";
import {
    alertCircleOutline, archiveOutline, checkmarkCircleOutline, checkmarkDoneCircleOutline,
    chevronForwardOutline,
    ellipseOutline,
    playCircleOutline, timeOutline
} from "ionicons/icons";
import { computed, PropType } from "vue";
import { useI18n } from "vue-i18n";
import Immobilie from "../../models/immobilie.model";

const props = defineProps({
  property: {
    type: Object as PropType<Immobilie>,
    required: true
  },
  selected: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["open"]);
const router = useIonRouter()

const { t } = useI18n({ useScope: 'global' })
const { user } = useUser();
const featureFlags = computed(() => user.value?.organisation?.featureFlags);
const username = computed(() => {
    const name = props.property.verwalters && props.property.verwalters[0] && props.property.verwalters[0].username ? props.property.verwalters[0].username : ''
    const words = name.split(' ')
    const firstLetters = words.map((word: string) => word[0])
    return firstLetters.join('')
});
const store = useStore();
const isMobile = computed(() => {
    return store.getters["app/isMobile"];
});
const { changePerson, changeStatus  } = useProperties()
const statusIconState = computed(() => {
    switch(props.property.status) {
        case ImmobilieStatus.ANGELEGT: return ellipseOutline;
        case ImmobilieStatus.GEPLANT: return timeOutline;
        case ImmobilieStatus.IN_DURCHFUEHRUNG: return playCircleOutline;
        case ImmobilieStatus.ABGESCHLOSSEN: return checkmarkCircleOutline;
        case ImmobilieStatus.FREIGEGEBEN: return checkmarkDoneCircleOutline;
        case ImmobilieStatus.ARCHIVIERT: return archiveOutline;
        default: return alertCircleOutline
    }
});

function openPropertyModal() {
    store.dispatch("app/setOpenedPropertyId", props.property.id);
    if (!isMobile.value) {
        router.push({
            path: `/property/${props.property.id}`
        })
    }
}

function open() {
  emit("open");
}

</script>
<style scoped lang="scss">
ion-item.selected-item {
  --background: #f8f9fb;
}
</style>