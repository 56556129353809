import { putBestandsaufnahme } from "@/api/ModelApi";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { Monitoring } from "@/utilities/monitoring";
import moment from "moment";
import { computed, Ref, ref, watch } from "vue";
import useToasts from "../useToasts";

export default function useDateChangeUtils(ba: Ref<Bestandsaufnahme | undefined>) {

    const isPerformingDatabaseUpdate = ref(false);

    const format = (date: string | Date) => moment(date).format();

    const initialBegehungsdate = ba.value?.begehungsdatum ? format(ba.value.begehungsdatum) : null;
    const initialSichtungsdate = ba.value?.sichtungsdatum ? format(ba.value.sichtungsdatum) : null;

    const changedBegehungsdate = ref(initialBegehungsdate);
    const changedSichtungsdate = ref(initialSichtungsdate);

    const lastBegehungsdate = ref(initialBegehungsdate);
    const lastSichtungsdate = ref(initialSichtungsdate);

    const store = useStore();
    const { updateErrorDateToast } = useToasts();
    const currentProject = computed(() => store.state.user.currentUserProject);

    const resetDates = () => {
        setTimeout(() => {
            changedBegehungsdate.value = lastBegehungsdate.value;
            changedSichtungsdate.value = lastSichtungsdate.value;
        }, 300); // Timeout could be removed, just another indication for the user that something was tried and went wrong
    }

    const saveDateToDatabase = async (begehung: boolean) => {
        try {
            if (!ba.value) {
                throw new Error("No survey found to update date");
            }
            const isOnline = store.getters["app/isOnline"];
            let updatedBaJson = null;

            if (isOnline) {
                isPerformingDatabaseUpdate.value = true;
                const fieldsToSet: any = {};
                if (begehung && changedBegehungsdate.value) {
                    fieldsToSet.begehungsdatum = changedBegehungsdate.value;
                } else if (changedSichtungsdate.value) {
                    fieldsToSet.sichtungsdatum = changedSichtungsdate.value;
                } else {
                    return;
                }
                const oldSurvey = await ba.value.toClassJson();
                updatedBaJson = await putBestandsaufnahme(ba.value.id, currentProject.value.id, fieldsToSet, oldSurvey);
            } else {
                updatedBaJson = await ba.value.toClassJson();
                if (begehung && changedBegehungsdate.value) {
                    updatedBaJson.begehungsdatum = changedBegehungsdate.value;
                } else if (changedSichtungsdate.value) {
                    updatedBaJson.sichtungsdatum = changedSichtungsdate.value;
                } else {
                    return;
                }
                updatedBaJson.hasUnsavedChanges = true;
            }

            updatedBaJson.isLocal = false; // legacy property, make sure it is not set to true
            await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBaJson });

            if(ba.value.isDownloaded) {
                // just using updatedBaJson did not work
                const storedBa = BestandsaufnahmeModel.find(updatedBaJson.id);
                await BestandsaufnahmeModel.dispatch('$updateLocally', { data: storedBa });
            }

            const updatedBa = new Bestandsaufnahme(updatedBaJson);
            store.commit('currentHzba/setCurrentBa', updatedBa);
        } catch (error: any) {
            Monitoring.chainError(`Error while setting ${begehung ? "Begehungsdatum" : "Sichtungsdatum"} manually`, error);
            resetDates();
            updateErrorDateToast();
        } finally {
            isPerformingDatabaseUpdate.value = false;
        }
    };

    const saveBegehungsDate = () => saveDateToDatabase(true);
    const saveSichtungsDate = () => saveDateToDatabase(false);

    watch(() => ba.value?.begehungsdatum, (newVal) => {
        if (newVal) {
            lastBegehungsdate.value = changedBegehungsdate.value;
            changedBegehungsdate.value = format(newVal);
        }
    });

    watch(() => ba.value?.sichtungsdatum, (newVal) => {
        if (newVal) {
            lastSichtungsdate.value = changedSichtungsdate.value;
            changedSichtungsdate.value = format(newVal);
        }
    });

    return {
        isPerformingDatabaseUpdate,
        changedBegehungsdate,
        changedSichtungsdate,
        saveBegehungsDate,
        saveSichtungsDate
    };
}