import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d639feb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateHzbaCard = _resolveComponent("CreateHzbaCard")!
  const _component_hzba_card = _resolveComponent("hzba-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.openedPropertyId)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2 gap-5", [
        _ctx.propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
        _ctx.propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
      ]
      ])
        }, [
          (_ctx.propertyView && _ctx.featureFlags?.createSurvey)
            ? (_openBlock(), _createBlock(_component_CreateHzbaCard, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAufnahmenModal()))
              }))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hzbas, (ba) => {
            return (_openBlock(), _createBlock(_component_hzba_card, {
              key: ba.id,
              ba: ba,
              "ui-mode": _ctx.uiMode
            }, null, 8, ["ba", "ui-mode"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.openedPropertyId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.hzbas.length > 1)
            ? (_openBlock(), _createBlock(_component_swiper, {
                key: 0,
                class: "custom-swiper",
                modules: _ctx.modules,
                "slides-per-view": 1,
                "space-between": 24,
                navigation: "",
                pagination: false,
                scrollbar: { draggable: true },
                virtual: true,
                breakpoints: {
          640: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: 3, spaceBetween: 30 }
        }
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hzbas, (ba) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, {
                      key: ba.id,
                      "virtual-index": ba.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_hzba_card, {
                          ba: ba,
                          "ui-mode": _ctx.uiMode
                        }, null, 8, ["ba", "ui-mode"])
                      ]),
                      _: 2
                    }, 1032, ["virtual-index"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules"]))
            : _createCommentVNode("", true),
          (_ctx.hzbas.length === 1)
            ? (_openBlock(), _createBlock(_component_hzba_card, {
                key: 1,
                ba: _ctx.hzbas[0],
                "ui-mode": _ctx.uiMode
              }, null, 8, ["ba", "ui-mode"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}