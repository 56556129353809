<template>
  <div class="flex flex-col bg-white z-50">
    <!-- Image -->
    <div>
      <div class="relative h-48">
        <a-image
          image-class="object-cover h-full w-full"
          data-cy="immobilie-vorschaubild"
          :src="getPhotoUrl(property?.vorschaubild, { thumbnail: true})"
        />
        <div class="grid justify-items-end absolute bottom-1 right-1">
          <AButton
            v-if="canAccessPhotos && property.vorschaubild"
            :type="'submit'"
            :btn-tertiary="true"
            data-cy="immobilie-vorschaubild"
            @click="$emit('addPreview')"
          >
            {{ $t("immobilie.buttons.vorschaubildAendern") }}
          </AButton>
          <AButton
            v-if="canAccessPhotos && !property.vorschaubild"
            :btn-tertiary="true"
            data-cy="immobilie-vorschaubild-hinzufuegen"
            @click="$emit('addPreview')"
          >
            {{ $t("immobilie.buttons.vorschaubildHinzufuegen") }}
          </AButton>

          <AButton
            v-if="canAccessPhotos"
            :btn-tertiary="true"
            data-cy="immobilie-weitere-bilder-hinzufuegen"
            @click="$emit('addMorePhotos')"
          >
            {{ $t("immobilie.buttons.weitereFotoshinzufuegen") }}
          </AButton>

          <AButton
            v-if="property?.bilder?.length > 0"
            :btn-tertiary="true"
            @click="$emit('openGallery')"
          >
            {{ $t("immobilie.buttons.weitereAnzeigen") }}
          </AButton>
        </div>
      </div>
    </div>
    <!-- Fields -->

    <div
      class="flex-1 pt-4 px-4 pb-2"
      data-cy="immobilie-stammdaten"
    >
      <div>
        <slot name="fields" />
      </div>
    </div>
  </div>
</template>


<script
    setup
    lang="ts"
>
import AButton from '@/components/Base/AButton.vue';
import AImage from '@/components/Base/AImage.vue';
import InfoField from "@/components/properties/InfoField.vue";
import Immobilie from '@/models/immobilie.model';
import { translatedPropertyStatus } from '@/models/immobilie/interfaces/IImmobilie';
import { getPhotoUrl } from '@/utilities/get-media-url';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
    property: {
        type: Object as PropType<Immobilie>,
        required: true
    },
    canAccessPhotos: {
        type: Boolean,
        required: true
    }
});

defineEmits(["addPreview", "addMorePhotos", "openGallery"]);

</script>


<style
    scoped
    lang="scss"
></style>