<template>
  <ion-page>
    <toolbar
      :title="t(`${organisationPrefix}toolbar.mobile.objectDetail`) + ' - ' + (property?.externeObjektNr ?? 'loading...')"
    >
      <template 
        v-if="!isMobile" 
        #leading
      >
        <ion-buttons slot="start">
          <ion-button
            fill="clear"
            class="px-0 -ml-4"
            size="small"
            @click="() => goBackFromPoperty()"
          >
            <ion-icon
              :icon="arrowBack"
              class="ml-white-text"
              size="large"
            />
          </ion-button>
        </ion-buttons>
      </template>
      <template
        v-else
        #trailing
      >
        <ion-buttons slot="end">
          <ion-button
            fill="clear"
            @click="() => goBackFromPoperty()"
          >
            <ion-icon
              :icon="closeOutline"
              class="ml-white-text"
              size="large"
              :title="$t('immobilie.buttons.closeDetailPage')"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </toolbar>
    <ion-content>
      <div
        v-if="!property"
        class="container"
      >
        <skeleton />
      </div>
      <div
        v-else
        class="bg-gray-100"
        :class="isMobile ? '' : ' lg:mt-2 md:mt-8'"
      >
        <div class="lg:container lg:flex lg:mx-auto">
          <div class="lg:w-1/3">
            <property-info 
              v-if="isMobile"
              :property="property"
              :can-access-photos="canAccessPhotos"
              @add-preview="addVorschaubild()"
              @add-more-photos="addImmoPhoto()"
              @open-gallery=" openBaseModal('gallery', {
                images: property?.bilder,
                title: 'Fotos',
                allowAddMore: true
              })"
            >
              <template #fields>
                <property-action-buttons
                  :property="property"
                  :is-person-button-enabled="featureFlags?.property?.changePerson"
                  :is-status-button-enabled="featureFlags?.property?.changeStatus"
                  :is-share-button-enabled="isNetworkConnected"
                  :is-locate-button-enabled="true"
                  @change-person="changePerson(property, t)"
                  @change-status="changeStatus(property, t)"
                  @share="handlePropertyShare(property.id.toString(), t)"
                  @locate="redirectToMap({ lon: property.geolocationLon, lat: property.geolocationLat })"
                />
              </template>
            </property-info>
            <div
              v-else
              class="
                overview-item
                flex flex-col-reverse
                md:flex-row
                lg:flex-col-reverse lg:mt-8
                bg-white
                lg-boxed-container
                z-50
                top-0
                lg:top-4
              "
              style="margin-right: 0"
            >
              <div
                class="flex-1 p-4 md:p-8 lg:p-4"
                data-cy="immobilie-stammdaten"
              >
                <InfoField :title="t(`${organisationPrefix}immobilie.adresse`)">
                  <div>
                    <div class="text-lg">
                      {{ property.strasse }}
                    </div>
                    <div class="text-lg mb-4">
                      {{ property.plz + " " + property.stadt }}
                    </div>
                  </div>
                </InfoField>
                <InfoField
                  :title="t(`${organisationPrefix}immobilie.vorhabentraeger`)"
                  :value="t(`${organisationPrefix}immobilie.vorhabentraegerValue`)"
                />
                <InfoField
                  :title="t('immobilie.eigentuemer')"
                  :separate-line="false"
                  :value="property.eigentuemer"
                />
                <InfoField
                  :title="t(`${organisationPrefix}immobilie.objektnummer`)"
                  :value="property.name"
                />
                <InfoField
                  v-if="featureFlags?.property?.changeStatus"
                  :title="t('immobilie.status')"
                  :value="translatedPropertyStatus(property.status,t)"  
                />
                <InfoField
                  v-if="featureFlags?.property?.changePerson"
                  :title="t('immobilie.person')"
                  :value="property.verwalters?.map(item => item.username).join(', ')"
                />
                <div
                  v-if="canCreateUpdateObject && (featureFlags?.property?.changeStatus || featureFlags?.property?.changePerson)"
                  class="flex flex-col items-end justify-end gap-2"
                >
                  <AButton
                    v-if="featureFlags?.property?.changeStatus"
                    :btn-tertiary="true"
                    data-cy="immobilie-status"
                    @click="changeStatus(property, t)"
                  >
                    {{ t('immobilie.updateStatus') }}
                  </AButton>
                  <AButton
                    v-if="featureFlags?.property?.changePerson"
                    :btn-tertiary="true"
                    data-cy="immobilie-person"
                    @click="changePerson(property, t)"
                  >
                    {{ t('immobilie.updatePerson') }}
                  </AButton>
                  <AButton
                    v-if="featureFlags?.property?.editProperty"
                    :btn-tertiary="true"
                    data-cy="immobilie-data"
                    @click="openPropertyEditModal()"
                  >
                    {{ t('immobilie.editProperty') }}
                  </AButton>
                  <div
                    v-if="isNetworkConnected"
                    class="flex cursor-pointer"
                    @click="$event.stopPropagation(), handlePropertyShare( property.id, t )"
                  >
                    <ion-icon
                      size="large"
                      :icon="shareSocialOutline"
                    />
                  </div>
                </div>

                <div class="mt-4 mb-0">
                  <div
                    v-for="pool in groupedBaPool"
                    :key="pool.identifier"
                  >
                    <AButton
                      v-if="
                        pool.latestBericht &&
                          pool.identifier.indexOf('hzba') >= 0
                      "
                      :btn-primary="true"
                      class="mt-2"
                      shape="round"
                      :data-cy="`button-bericht-${pool.identifier}-anzeigen`"
                      @click="
                        openBaseModal('pdf.view', { pdf: pool.latestBericht })
                      "
                    >
                      <ion-label>
                        {{
                          t("immobilie.buttons.berichtAnzeigen1") +
                            " " +
                            pool.titleBericht +
                            " " +
                            t("immobilie.buttons.berichtAnzeigen2")
                        }}
                      </ion-label>
                    </AButton>

                    <AButton
                      v-else-if="pool.latestBericht"
                      :btn-primary="true"
                      class="mt-2"
                      :data-cy="`button-bericht-${pool.identifier}-anzeigen`"
                      @click="
                        openBaseModal('pdf.view', { pdf: pool.latestBericht })
                      "
                    >
                      <ion-label>
                        {{
                          t("immobilie.buttons.berichtAnzeigen1") +
                            " " +
                            pool.titleBericht +
                            " " +
                            t("immobilie.buttons.berichtAnzeigen2")
                        }}
                      </ion-label>
                    </AButton>
                  </div>

                  <!-- BUTTON FÜR PDF-DOWNLOAD -->
                  <!-- <AFlatButton
                      v-if="property.technikzentraleBericht && property.technikzentraleBericht.length > 0 && property.technikzentraleBericht[0]"
                    data-cy="button-berichtTechnikzentraleAnzeigen"
                    class="mt-2"
                    @click="openBaseModal('pdf.view', { pdf: property.technikzentraleBericht[0] })"
                  >
                    <ion-label>{{ t("immobilie.buttons.berichtTechnikzentraleAnzeigen") }}</ion-label>
                  </AFlatButton> -->

                  <AButton
                    v-if="
                      property.anlageschema &&
                        property.anlageschema.length > 0 &&
                        property.anlageschema[0]
                    "
                    :btn-primary="true"
                    class="mt-2"
                    data-cy="button-anlagenschemaAnzeigen"
                    @click="
                      openBaseModal('pdf.view', {
                        pdf: property.anlageschema[0],
                      })
                    "
                  >
                    {{ t("immobilie.buttons.anlagenschemaAnzeigen") }}
                  </AButton>

                  <!--                  <AFlatButton-->
                  <!--                    class="mt-2 light-button normal-case"-->
                  <!--                    data-cy="btn-pdf-anlagenschemaAnzeigen"-->
                  <!--                    @click="$router.push(`/property/${property.id}/pdf-preview-anlagenschema`)"-->
                  <!--                  >-->
                  <!--                    {{ $t("immobilie.buttons.anlagenschemaAnzeigen") }}-->
                  <!--                  </AFlatButton>-->

                  <AButton
                    v-if="
                      property.technikzentraleBericht &&
                        property.technikzentraleBericht.length > 0 &&
                        property.technikzentraleBericht[0]
                    "
                    :btn-primary="true"
                    data-cy="button-berichtTechnikzentraleAnzeigen"
                    class="mt-2"
                    @click="
                      openBaseModal('pdf.view', {
                        pdf: property.technikzentraleBericht[0],
                      })
                    "
                  >
                    <ion-label>
                      {{
                        t("immobilie.buttons.berichtTechnikzentraleAnzeigen")
                      }}
                    </ion-label>
                  </AButton>
                </div>
              </div>

              <div
                class="w-auto md:w-auto"
                style="min-width: 300px"
              >
                <div class="relative lg:w-auto h-48 md:h-96 md:m-4">
                  <a-image
                    image-class="object-cover h-full w-full -lg:m-4 md:rounded-lg"
                    data-cy="immobilie-vorschaubild"
                    :src="getPhotoUrl(property?.vorschaubild)"
                  />
                  <!-- <a-image
                    v-if="mapImageUrl && !property.vorschaubild"
                    image-class="object-cover h-full w-full -lg:m-4 md:rounded-lg"
                    data-cy="immobilie-vorschaubild"
                    :src="mapImageUrl"
                  /> -->
                  <div
                    class="
                      absolute
                      bottom-0
                      left-0
                      right-0
                      grid
                      justify-items-end
                    "
                  >
                    <AButton
                      v-if="canAccessPhotos && property.vorschaubild"
                      :type="'submit'"
                      :btn-tertiary="true"
                      data-cy="immobilie-vorschaubild"
                      @click="addVorschaubild()"
                    >
                      {{ t("immobilie.buttons.vorschaubildAendern") }} 
                    </AButton>
                    <AButton
                      v-if="canAccessPhotos && !property.vorschaubild"
                      :btn-tertiary="true"
                      data-cy="immobilie-vorschaubild-hinzufuegen"
                      @click="addVorschaubild()"
                    >
                      {{ t("immobilie.buttons.vorschaubildHinzufuegen") }} 
                    </AButton>

                    <AButton
                      v-if="canAccessPhotos"
                      :btn-tertiary="true"
                      data-cy="immobilie-weitere-bilder-hinzufuegen"
                      @click="addImmoPhoto()"
                    >
                      {{ t("immobilie.buttons.weitereFotoshinzufuegen") }}
                    </AButton>

                    <AButton
                      v-if="property?.bilder?.length > 0"
                      :btn-tertiary="true"
                      @click="
                        openBaseModal('gallery', {
                          images: property?.bilder,
                          title: 'Fotos',
                          allowAddMore: true
                        })
                      "
                    >
                      {{ t("immobilie.buttons.weitereAnzeigen") }}
                    </AButton>
                  </div>

                  <!--
                  <div
                    v-if="property?.bilder?.length > 0"
                    class="show-more absolute bottom-0 left-0 right-0 cursor-pointer"
                    @click="openBaseModal('gallery', { images: property?.bilder, title: 'Fotos' })"
                  >
                    {{ t("immobilie.buttons.weitereAnzeigen") }}
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>

          <div
            id="media-box"
            class="lg:w-2/3"
          >
            <div
              v-if="featureFlags.chat && immobilieLoaded"
              class="mt-8 boxed-container"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 mb-2 flex-1">
                  Kommunikation
                </h1>
              </div>
              <ChatComponent
                width="100%"
                height="500"
                :rocket-chat-url="`https://movinglayers.echo-dc.eu/channel/${chatChannelName}?layout=embedded`"
              />
            </div>
          
            <div
              v-if="featureFlags?.property?.map"
              class="mt-8 boxed-container"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 mb-2 flex-1">
                  {{ t('immobilie.mapLabel') }}
                </h1>
              </div>
              <div
                class="p-3"
                style="height: 500px"
              >
                <AppMap
                  style="height: 100%"
                  :identifier="'propertyMap'"
                  :map-settings="mapConfig"
                  :property-location="{lat: property.geolocationLat, lon: property.geolocationLon}"
                />
              </div>
            </div>

            <div
              v-if="property.imageVideoLink"
              class="mt-8 boxed-container"
              data-cy="immobilie-video"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 mb-2 flex-1">
                  {{ t("immobilie.video") }}
                </h1>
              </div>

              <div style="position: relative; padding-top: 58.25%">
                <div class="p-3 iframe-video">
                  <iframe
                    style="border-radius: 20px"
                    class="p-3 iframe-video"
                    :src="property.imageVideoLink"
                    frameborder="0"
                    allow="autoplay; encrypted-media; fullscreen"
                    @click.stop="clickedOnIframe"
                  />
                </div>
              </div>
            </div>


            <div
              v-if="
                featureFlags?.matterport &&
                  (property?.matterports?.length > 0) && (property.matterportsAnzeigen !== false)
              "
              class="mt-8 boxed-container"
            >
              <div
                class="flex items-center px-4 mt-4"
                data-cy="immobilie-3dAufnahmen"
              >
                <h1 class="my-0 flex-1">
                  {{ t("immobilie.3dAufnahmen") }}
                </h1>
                <IonButton
                  v-if="(!isMaxXSmall) && (property?.matterports?.length > 1)"
                  fill="clear"
                  class="normal-case"
                  data-cy="button-3dAufnahmen-anzeigen"
                  @click="
                    () =>
                      openBaseModal('gallery.iframes', {
                        iframes: property?.matterports,
                        title: '3D Aufnahmen',
                      })
                  "
                >
                  <ion-icon
                    slot="end"
                    :icon="chevronForwardOutline"
                  />
                  {{ t("immobilie.buttons.alleAnzeigen") }}
                </IonButton>
              </div>

              <div 
                v-if="(property.matterports.length === 1) && isImmobilienverwalter()"
                style="position: relative; padding-top: 58.25%"
              >
                <horizontal-item-scroll
                  class="p-3 iframe-video" 
                  data-cy="mappedMatterports"
                  :data="mappedMatterports"
                  :photo-mode="false"
                  :show-only-one="(property.matterports.length === 1)"
                  @onIframeItemClicked="
                    (url) =>
                      openBaseModal('gallery.iframe', {
                        iframe: url,
                        title: '3D Aufnahme',
                      })
                  "
                />
              </div>
            

              <div 
                v-else
                class="bg-white"
              >
                <horizontal-item-scroll
                  data-cy="mappedMatterports"
                  :data="mappedMatterports"
                  :photo-mode="false"
                  @onIframeItemClicked="
                    (url) =>
                      openBaseModal('gallery.iframe', {
                        iframe: url,
                        title: '3D Aufnahme',
                      })
                  "
                />
              </div>

              <AFlatButton
                v-if="isMaxXSmall && property.matterports?.length > 0"
                :expand="true"
                class="mb-4 mx-4"
                @click="
                  () =>
                    openBaseModal('gallery.iframes', {
                      iframes: property?.matterports,
                      title: '3D Aufnahmen',
                    })
                "
              >
                {{ t("immobilie.buttons.alleAnzeigen") }}
              </AFlatButton>
            </div>

            <div
              v-if="(property?.drohnenbilder?.length > 0) && (property.drohnenbilderAnzeigen !== false)"
              class="mt-8 boxed-container"
              data-cy="immobilie-drohnenAufnahmen"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 mb-0 flex-1">
                  {{ t("immobilie.drohnenAufnahmen") }}
                </h1>
                <IonButton
                  v-if="!isMaxXSmall && property.drohnenbilder?.length > 0"
                  fill="clear"
                  class="normal-case"
                  data-cy="button-drohnenAufnahmen-anzeigen"
                  @click="
                    () =>
                      openBaseModal('gallery', {
                        images: property?.drohnenbilder,
                        title: 'Drohnenbilder',
                        downloadEnabled: true,
                      })
                  "
                >
                  {{ t("immobilie.buttons.alleAnzeigen") }}
                </IonButton>
              </div>

              <div class="bg-white">
                <horizontal-item-scroll
                  data-cy="drohnenbilder"
                  :data="mappedDrohnenbilder"
                />
              </div>

              <AFlatButton
                v-if="isMaxXSmall && property.drohnenbilder?.length > 0"
                :expand="true"
                class="mb-4 mx-4"
                @click="
                  () =>
                    openBaseModal('gallery', {
                      images: property?.drohnenbilder,
                      title: 'Drohnenbilder',
                      downloadEnabled: true,
                    })
                "
              >
                {{ t("immobilie.buttons.alleAnzeigen") }}
              </AFlatButton>
            </div>

            <div
              v-if="(property?.grundrisss?.length > 0) && (property.grundrissAnzeigen !== false)"
              class="mt-8 boxed-container"
              data-cy="immobilie-grundrisse"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 flex-1">
                  {{ t("immobilie.grundrisse") }}
                </h1>

                <IonButton
                  v-if="!isMaxXSmall && property.grundrisss?.length > 0"
                  fill="clear"
                  class="normal-case"
                  data-cy="button-grundrisseAnzeigen"
                  @click="
                    () =>
                      openBaseModal('gallery', {
                        images: mappedGrundrissPhotos,
                        title: 'Grundrisse',
                        downloadEnabled: true,
                      })
                  "
                >
                  {{ t("immobilie.buttons.alleAnzeigen") }}
                </IonButton>
              </div>

              <div class="bg-white">
                <horizontal-item-scroll
                  data-cy="mappedGrundrissData"
                  :data="mappedGrundrissData"
                  :photo-mode="false"
                />
              </div>

              <AFlatButton
                v-if="isMaxXSmall && property.grundrisss?.length > 0"
                :expand="true"
                class="mb-4 mx-4"
                @click="
                  () =>
                    openBaseModal('gallery', {
                      images: mappedGrundrissPhotos,
                      title: 'Grundrisse',
                      downloadEnabled: true,
                    })
                "
              >
                {{ t("immobilie.buttons.alleAnzeigen") }}
              </AFlatButton>
            </div>
            <div
              v-for="groupedBas, index in groupedBaPool"
              :key="groupedBas.identifier"
              class="mt-8 boxed-container-margin"
              :data-cy="`immobilie-${groupedBas.identifier}`"
            >
              <div class="flex pb-2 items-center">
                <h1 class="flex-1 pl-4 text-2xl md:text-3xl smaller-header-margin">
                  {{ groupedBas.title }}
                </h1>
              </div>

              <HzbaList
                :class="{ 'pb-4': index === groupedBaPool.length - 1 }"
                :data-cy="`list-${groupedBas.title}`"
                :hzbas="groupedBas.bas"
                :has-report="true"
                :property="property"
                :template-identifier="groupedBas.identifier"
                property-view
              />
            </div>

            <!-- START: Old element to show documents of all types, reactive if needed -->

            <!-- <div
              v-if="otherDokuments?.length > 0"
              class="mt-8 boxed-container"
              data-cy="immobilie-other-Dokuments"
            >
              <div class="flex items-center px-4 mt-4">
                <h1 class="my-0 flex-1">
                  {{ t("immobilie.dokumente.dokumente") }}
                </h1>

                <IonButton
                  v-if="!isMaxXSmall && otherDokuments?.length > 0"
                  fill="clear"
                  class="normal-case"
                  data-cy="button-pdfs-anzeigen"
                  @click="
                    () =>
                      openBaseModal('gallery.pdf', {
                        pdfs: mappedDokumentPhotos,
                        title: 'Dokumente',
                        downloadEnabled: true,
                      })
                  "
                >
                  {{ t("immobilie.buttons.alleAnzeigen") }}
                </IonButton>
              </div>

              <div class="bg-white">
                <horizontal-item-scroll
                  data-cy="mappedDokumentData"
                  :data="mappedDokumentData"
                  :photo-mode="false"
                />
              </div>

              <AFlatButton
                v-if="isMaxXSmall && otherDokuments?.length > 0"
                :expand="true"
                class="mb-4 mx-4"
                data-cy="button-pdfs-anzeigen"
                @click="
                  () =>
                    openBaseModal('gallery.pdf', {
                      pdfs: mappedDokumentPhotos,
                      title: 'Dokumente',
                      downloadEnabled: true,
                    })
                "
              >
                {{ t("immobilie.buttons.alleAnzeigen") }}
              </AFlatButton>
            </div> -->
            <!-- END: Old element to show documents of all types, reactive if needed -->

            <!-- START: New element to show documents selected by types -->

            <div
              v-if="featureFlags?.property?.uploadDocument" 
              class="mt-8 boxed-container"
            >
              <h1 class="flex-1 pl-4 text-2xl md:text-3xl">
                {{ t('immobilie.dokumente.dokumente') }}
              </h1>
              <div class="grid grid-cols-1 auto-rows-fr lg:p-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
                <div
                  v-for="(item, i) in allDokumentsUnsorted"
                  :key="i"
                  class="mt-8 boxed-container shadow-lg"
                >
                  <DocumentItem :document="item" />
                </div>
                <div
                  v-if="canCreateUpdateObject"
                  class="shadow-lg boxed-container"
                >
                  <DragAndDropDocument
                    :property-id="property.id"
                    class="h-full"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="featureFlags?.property?.notes"
              class="mt-8 boxed-container shadow-lg"
            >
              <div class="px-4 mt-4">
                <h1 class="my-0">
                  {{ t('propertyNotes.title') }}
                </h1>
                <div>
                  <PropertyNotes
                    :property-id="property.id"
                    :notes="property.notes || []"
                  />
                </div>
              </div>
            </div>
            <!-- END: New element to show documents selected by types -->
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import AFlatButton from "@/components/Base/AFlatButton.vue";
import AImage from "@/components/Base/AImage.vue";
import PdfModal from "@/components/Base/PdfModal.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import Skeleton from "@/components/Skeleton.vue";
import HorizontalItemScroll from "@/components/hzba/HorizontalItemScroll.vue";
import HzbaList from "@/components/hzba/HzbaList.vue";
import MaengelUebersichtInImmobilileModal from "@/components/properties/CSVMangelliste.vue";
import ChatComponent from "@/components/properties/ChatComponent.vue";
import EditPropertyModal from '@/components/properties/EditPropertyModal.vue';
import InfoField from "@/components/properties/InfoField.vue";
import PropertyActionButtons from "@/components/properties/PropertyActionButtons.vue";
import PropertyInfo from "@/components/properties/PropertyInfo.vue";
import AppMap from '@/components/v2/App/AppMap.vue';
import DocumentItem from '@/components/v2/Property/DocumentItem.vue';
import DragAndDropDocument from '@/components/v2/Property/DragAndDropDocument.vue';
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";
import { useProperties } from "@/composables/Property/useProperties";
import { usePhotoRecording } from "@/composables/usePhotoRecording";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import Immobilie from "@/models/immobilie.model";
import { translatedPropertyStatus } from "@/models/immobilie/interfaces/IImmobilie";
import User from "@/models/user";
import PropertyNotes from '@/pages/property/_id/PropertyNotes.vue';
import getLocalization from "@/utilities/get-localization";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import { savePictureLocal } from '@/utilities/photo-storage';
import { Network } from "@capacitor/network";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonImg,
    IonLabel,
    IonPage,
    modalController,
    onIonViewWillEnter
} from "@ionic/vue";
import { addCircleOutline, arrowBack, chevronForwardOutline, closeOutline, shareSocialOutline } from "ionicons/icons";
import { ComputedRef, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { environment } from "../../../../environments/environment";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";

export default defineComponent({
  name: "PropertyPage",
  components: {
    PropertyNotes,
    AButton,
    AFlatButton,
    AImage,
    ChatComponent,
    HorizontalItemScroll,
    InfoField,
    Toolbar,
    IonPage,
    IonButtons,
    IonBackButton,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonLabel,
    IonContent,
    IonImg,
    PdfModal,
    HzbaList,
    Skeleton,
    DocumentItem,
    DragAndDropDocument,
    AppMap,
    PropertyInfo,
    PropertyActionButtons
    // DokumentList,
    // ManagementSummaryList,
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });
    const router = useRouter();
    const store = useStore();
    const { isMaxXSmall } = useScreenSize();
    const immobilieLoaded = ref(false)
    const openedPropertyId = computed( () => store.getters["app/openedPropertyId"] );
    const propertyId = computed(() => { 
        return openedPropertyId.value || Number.parseFloat("" + router.currentRoute.value.params.propertyId)
    });
    const property = computed(() => {
      return Immobilie.query().with("bestandsaufnahmes").find(propertyId.value);
    })
    const chatChannelName = computed(() => sanitizeString( property.value?.name as string) + ( environment.ENVIRONMENT === "prod" ? "" : "_" + environment.ENVIRONMENT ));
    const appConfig = computed(() => store.state.app.appConfig)
    const currentProject = computed(() => store.state.user.currentUserProject)
    const isMobile = computed(() => {
      return store.getters["app/isMobile"];
    });

    const { changePerson, changeStatus, fetchFullProperty, handlePropertyShare, redirectToMap } = useProperties()
    const isNetworkConnected = computed(() => store.state.app.networkConnected);

    watch(
      () => propertyId,
      async () => {
        await fetchFullProperty(propertyId.value);
        immobilieLoaded.value = true
      },
      { immediate: true}
    )

    /** NOTE: Needed if you want to group documents of all types */

    // const otherDokuments: any = computed(() => {
    //   if (!property.value?.dokuments || property.value?.dokuments?.length === 0) {
    //     return;
    //   }
    //   return property.value?.dokuments?.filter(
    //     (doc) => doc.typ !== "MANAGEMENT_SUMMARY"
    //   );
    // });

    /** NOTE: Needed if you want to display management summaries as list of documents */

    // const mgmtSummaries: any = computed(() => {
    //   if (!property.value?.dokuments || property.value?.dokuments?.length === 0) {
    //     return;
    //   }
    //   return property.value?.dokuments?.filter(
    //     (doc) => doc.typ === "MANAGEMENT_SUMMARY"
    //   );
    // });

    const { user, isGutachter, isProjektverantwortlicher, isImmobilienverwalter, isBearbeiter, isLeser, canCreateUpdateObject } = useUser();
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);

    const canAccessPhotos = computed<boolean>(() => {
      return  ( isGutachter() || isProjektverantwortlicher() || isBearbeiter() || isLeser() );
    });

    const mapConfig = {
      ...currentProject.value?.mapConfig?.map,
      mapCenter: [property.value?.geolocationLon, property.value?.geolocationLat]
    }

    const allDokumentsUnsorted: any = computed(() => {
      if (!property.value?.dokuments || property.value?.dokuments?.length === 0) {
        return;
      }
      return property.value?.dokuments
    });

    const getAllSortedDokuments = computed(() => {
      const docTypes:any = {};

      if (!allDokumentsUnsorted.value || allDokumentsUnsorted.value.length === 0) {
        return
      }

      allDokumentsUnsorted.value.forEach((dok:any) => {
          if (!(dok.typ in docTypes)) {
          docTypes[dok.typ] = [];
        }
        docTypes[dok.typ].push(dok);
      });

      const docsSortedAlphabetically = Object.keys(docTypes).sort().reduce((objEntries:any, key) => {
        objEntries[key] = docTypes[key];
        return objEntries;
      }, {});

      return docsSortedAlphabetically;
    });

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
      fetchFullProperty(propertyId.value);
    });

    const mappedDrohnenbilder = computed(() => {
      return property.value?.drohnenbilder?.map((el) => {
        return { title: "", media: el };
      });
    });

    watch(
      () => property.value?.bestandsaufnahmes,
      () => {
        property.value?.bestandsaufnahmes?.sort((a, b) => {
          return a.begehungsdatum < b.begehungsdatum ? -1 : 1;
        });
      }
    );

    const mappedGrundrissData = computed(() => {
      if (!property.value?.grundrisss) {
        return [];
      }

      return property.value?.grundrisss?.map((el) => {
        return { title: el.titel && getLocalization(el.titel), media: el.data };
      });
    });

    const mappedGrundrissPhotos = computed(() => {
      return property.value?.grundrisss?.map((el) => {
        return el.data;
      });
    });

    /** NOTE: Needed if you want to group documents of all types  */

    // const mappedDokumentData = computed(() => {
    //   if (!otherDokuments.value) {
    //     return [];
    //   } 
    //   return otherDokuments?.value.map((el:any) => {
    //     return { title: el.name && getLocalization(el.name), media: el.dokument };
    //   });
    // });

    /** NOTE: Needed if you want to group documents of all types  */

    // const mappedDokumentPhotos = computed(() => {
    //   return otherDokuments?.value.map((el:any) => {
    //     return el.dokument;
    //   })
    // });

    const sanitizeString = ( name: string ) => {
      return name
        .replace('ä', 'ae')
        .replace('ü', 'ue')
        .replace('ö', 'oe')
        .replace('ß', 'ss')
        .replace(/\s+/g, '_') // Replace whitespace with underscores
        .replace(/[^a-zA-Z0-9_]/g, '') // Remove any non-alphanumeric characters except underscores
    };

    const clickedOnIframe = (e: any) => {
      e.stopPropagation();
    };

    const mappedMatterports = computed(() => {
      if (
        !property.value?.matterports ||
        !Array.isArray(property.value?.matterports)
      ) {
        return;
      }

      return property.value?.matterports?.map((el) => {
        return {
          title: el.titel && getLocalization(el.titel),
          media: {
            url: el.url,
            ext: "iframe",
          },
        };
      });
    });

    const verwalterLabel = computed(() => {
      if (
        !property.value?.verwalters ||
        property.value?.verwalters?.length === 0
      )
        return;
      return property.value?.verwalters[0].organisation;
    });

    const recommendedBericht = (hzba: any) => {
      if (hzba.berichts && hzba.berichts?.length > 0) {
        const bericht = hzba.berichts.find(
          (el: any) => el.kennzeichen.indexOf("-de") >= 0
        ); //TODO support multilingual
        return bericht && bericht.data;
      }
      return hzba.bericht;
    };

    const recommendedMangelliste = (ba: any) => {
      if (ba.mangellists && ba.mangellists?.length > 0) {
        // console.log('ba.mangellists: ', ba.mangellists[0].mangelvorlage.kategorie);
      }
      return ba.mangellists;
    };

    const findLastBaWithReport = (identifier: string) => {
      return property.value?.bestandsaufnahmes
        ?.slice()
        .reverse()
        .find(
          (ba) =>
            identifier === ba.identifier &&
            ba.berichts &&
            ba.berichts?.length > 0
        );
    };

    const openMaengelInImmobilie = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.immobilie.uebersicht", { ba: props.ba });
    };

    const { openPhotoDialog } = usePhotoRecording();

    const addVorschaubild = async () => {
      const { uploadVorschaubild } = useImmobilieHelper(
        property.value as Immobilie
      );
      const uploadLimit = 1; // only 1 preview picture can be stored on strapi
      const photoRes = await openPhotoDialog(t, uploadLimit, {
        hideFile: true,
      });

      const networkStatus = await Network.getStatus();
      
      if (networkStatus.connected) {
        photoRes.success &&
          photoRes.data &&
          photoRes.data.length > 0 &&
          (await uploadVorschaubild(photoRes.data[0], propertyId.value));
        fetchFullProperty(propertyId.value);
      } else {
        if (photoRes?.data?.length && photoRes?.data?.length > 0) {
          const imageResult = photoRes.data[0];
          const updateResult = await Immobilie.update({
            where: propertyId.value as any,
            data: {
              vorschaubild: imageResult
            }
          });
          await savePictureLocal(imageResult)
          await Immobilie.dispatch('$updateLocally', { data: updateResult });
          // await Immobilie.dispatch('addToPersistedProperties', updateResult);
        }
      }
    };

    const addImmoPhoto = async () => {
      const { uploadImmoBilder } = useImmobilieHelper(
        property.value as Immobilie
      );
      const uploadLimit = 10; // TODO: define or delete constraint
      const photoRes = await openPhotoDialog(t, uploadLimit, {
        hideFile: true,
      });

      const networkStatus = await Network.getStatus();
      if (networkStatus.connected) {
        photoRes.success &&
          photoRes.data &&
          photoRes.data.length > 0 &&
          (await uploadImmoBilder(photoRes.data, propertyId.value));
        fetchFullProperty(propertyId.value);
      } else {
        if (photoRes?.data?.length && photoRes?.data?.length > 0) {
          const imageResult = photoRes.data[0];
          const newBilder = property.value?.bilder && property.value?.bilder.length > 0 ? [ ...property.value.bilder, imageResult ] : [ imageResult ];
          const updateResult = await Immobilie.update({
            where: propertyId.value as any,
            data: {
              bilder: newBilder
            }
          });
          await savePictureLocal(imageResult)
          await Immobilie.dispatch('$updateLocally', { data: updateResult });
          // await Immobilie.dispatch('addToPersistedProperties', updateResult);
        }
      }
    };

    interface GroupedBaPool {
      identifier: string;
      bas: any;
      title: string;
      titleBericht: string;
      latestBericht: any;
      latestMangelliste: any;
    }

    const groupedBaPool = computed<GroupedBaPool[]>(() => {
      const baPool: GroupedBaPool[] = [];
      property.value?.bestandsaufnahmes?.forEach((el) => {
        const identifier = el.identifier;
        if (!identifier) {
          return;
        }

        const foundPool = baPool.find((el) => el.identifier === identifier);
        const foundBa = BestandsaufnahmeModel.query().find(el.id); // without this computed variable is not re-evaluated when survey changes
        let ba: Bestandsaufnahme;
        if (foundBa) {
          ba = new Bestandsaufnahme(foundBa);
        } else {
          ba = new Bestandsaufnahme(el);
        }
        if (foundPool) {
          foundPool.bas.push(ba);
        } else {
          baPool.push({
            title: el.nameMehrzahl
              ? getLocalization(el.nameMehrzahl)
              : getLocalization(el.name),
            identifier: identifier,
            titleBericht: getLocalization(
              el.nameBericht || el.nameMehrzahl || el.name
            ),
            latestBericht: undefined,
            latestMangelliste: undefined,
            bas: [ba],
          });
        }
      });

      baPool.forEach((pool) => {
        const lastBa = findLastBaWithReport(pool.identifier);
        if (lastBa) {
          pool.latestBericht = recommendedBericht(lastBa);
          pool.latestMangelliste = recommendedMangelliste(lastBa);
        }
      });

      return baPool;
    });

    function goBackFromPoperty() {
      const rec = store.state.app.recentRoute;
      if (router.currentRoute.value.path === "/properties-map") {
        store.dispatch("app/setOpenedPropertyId", null);
      } else if (rec.name === 'properties') {
        router.go(-1);
      } else {
        router.replace("/properties");
      }
    }
    
    // const mapImageUrl = ref("")
    // const handleMapLoaded = async (map: any) => {
    //   if(featureFlags.value.property.map) {
    //     const mapCanvas = map._canvas
    //     mapImageUrl.value = await mapCanvas.toDataURL()
    //   }
    // }

    return {
      // handleMapLoaded,
      // mapImageUrl,
      appConfig,
      property,
      addCircleOutline,
      arrowBack,
      chevronForwardOutline,
      getPhotoUrl,
      openBaseModal,
      addVorschaubild,
      addImmoPhoto,
      isImmobilienverwalter,
      canAccessPhotos,
      groupedBaPool,
      isMaxXSmall,
      mappedGrundrissData,
      mappedDrohnenbilder,
      mappedMatterports,
      verwalterLabel,
      mappedGrundrissPhotos,
      clickedOnIframe,
      openMaengelInImmobilie,
      MaengelUebersichtInImmobilileModal,
      recommendedMangelliste,
      getAllSortedDokuments,
      t,
      goBackFromPoperty,
      featureFlags,
      allDokumentsUnsorted,
      getLocalization,
      chatChannelName,
      immobilieLoaded,
      canCreateUpdateObject,
      organisationPrefix,
      translatedPropertyStatus,
      mapConfig,
      changePerson,
      changeStatus,
      isNetworkConnected,
      handlePropertyShare,
      shareSocialOutline,
      isMobile,
      redirectToMap,
      closeOutline
    };
  },
  methods: {
    async openPropertyEditModal() {
      const modal = await modalController.create({
        component: EditPropertyModal,
        cssClass: 'v2Modal fullScreenModal',
        componentProps: {
          property: this.property
        },
        canDismiss: true,
        //@ts-ignore
        presentingElement: this.$root.$refs.ionRouter.ionRouterOutlet
      });
      return modal.present();
    }
  }
});
</script>

<style scoped lang="scss">

ion-img::part(image) {
  border-radius: 10px;
}

.overview-item {
  position: sticky;
  align-self: flex-start !important;
  position: -webkit-sticky; /* for Safari */
}


.iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.report-button {
  max-height: unset;
  height: unset;
  --padding-top: 8px;
  --padding-bottom: 8px;
}

@media (min-width: 1024px) {
  #media-box:first-child {
    margin-top: 16px;
  }
}

h1.smaller-header-margin {
  margin-bottom: 0;
  margin-top: 0.25rem;
}

</style>
