<template>
  <ion-app>
    <div
      class="loadingSpinnerContainer"
      :class="{ displaySpinner : isLoadingSpinner }"
    >
      <div class="spinner-content">
        <ion-spinner
          name="crescent"
          :duration="650"
        />
      </div>
    </div>    <!--    <ion-page style="background: pink;">-->
    <AppLinkBanner v-if="showAppLink" />
    <ion-tabs
      :class="showAppLink ? 'app-link-shown' : ''"
    >
      <!--        <div :class="showBottomNavigation ? '' : 'bottomNavigationHidden' ">-->
      <!--          <ion-router-outlet :class="showBottomNavigation ? '' : 'bottomNavigationHidden'" />-->
      <!--        </div>-->
      <ion-router-outlet
        ref="ionRouter"
        :key="$route.fullPath"
        :animated="true"
      />
      <!-- NOTE: ion-tab-bar does not work in seperated component -->
      <ion-tab-bar
        v-show="showBottomNavigation"
        slot="bottom"
        class="ml-background ml-white-text bottomToolbar"
        :class=" isDesktopToolbarForbidden ? 'bottomNavigation' : ''"
      >
        <ion-tab-button
          tab="tab_properties_map"
          href="/properties-map"
          data-cy="toolbar-immobilien-map"
          class="ml-background"
        >
          <ion-icon
            :icon="mapOutline"
            class="ml-white-text" 
            :class="{ 'ml-forest-text': routeName === 'propertiesMap' }"
          />
        </ion-tab-button>

        <ion-tab-button
          tab="tab_properties"
          href="/properties"
          data-cy="toolbar-immobilien"
          class="ml-background"
        >
          <ion-icon
            :icon="listOutline"
            class="ml-white-text" 
            :class="{ 'ml-forest-text': routeName === 'propertiesList' }"
          />
        </ion-tab-button>

        <ion-tab-button
          v-if="!user.isImmobilienverwalter()"
          tab="tab_main"
          href="/bas"
          class="ml-background"
        >
          <ion-icon
            :icon="readerOutline"
            class="ml-white-text" 
            :class="{ 'ml-forest-text': routeName === 'bas' }"
          />
        </ion-tab-button>

        <ion-tab-button
          tab="tab_setting"
          href="/settings"
          data-cy="toolbar-einstellungen"
          class="ml-background"
        >
          <ion-icon
            :icon="settingsOutline"
            class="ml-white-text" 
            :class="{ 'ml-forest-text': routeName === 'settings' }"
          />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <!--    </ion-page>-->
    <dev-box v-if="isDev" />

    <update-screen v-if="isUpdating" />
  </ion-app>
</template>

<script lang="ts">
import AppLinkBanner from "@/components/AppLinkBanner.vue";
import DevBox from "@/components/DevBox";
import Navigation from "@/components/Navigation/Toolbar";
import UpdateScreen from "@/components/Other/UpdateScreen.vue";
import useBestandsaufnahmeSockets from '@/composables/Bestandsaufnahme/useBestandsaufnahmeSockets';
import { isDevEnvironment } from "@/composables/useEnvDetector";
import useFetchData from "@/composables/useFetchData";
import useNetworkState from "@/composables/useNetworkState";
import { useOTA } from "@/composables/useOTA";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import { initializePushNotifications } from "@/services/pushNotifications.service";
import { setCache } from '@/store/storage/ionicStorage';
import AppLogger from "@/utilities/AppLogger";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import {
    IonApp,
    IonHeader,
    IonIcon,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonSpinner,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonRouter
} from "@ionic/vue";
import { home, listOutline, mapOutline, optionsOutline, pinOutline, reader, readerOutline, settings, settingsOutline } from "ionicons/icons";
import { ComputedRef, computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRoute } from "vue-router";
import { environment } from "../environments/environment";
import ImmobilienIcon from "../public/assets/mlIcons/ImmobilienIcon.vue";
import SettingsIcon from "../public/assets/mlIcons/SettingsIcon.vue";
import ToolbarListIcon from "../public/assets/mlIcons/ToolbarListIcon.vue";
import useAuth from './composables/useAuth';
import { useCreateSurvey } from "./composables/useCreateSurvey";
import useUser from './composables/useUser';
import User from "./models/user";
import { handleOfflineData } from "./utilities/handle-offline-data";
import { Monitoring } from "./utilities/monitoring";

export default defineComponent({
  name: "App",
  components: {
    UpdateScreen,
    AppLinkBanner,
    Navigation,
    ImmobilienIcon,
    ToolbarListIcon,
    SettingsIcon,
    IonApp,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonSpinner,
    DevBox,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const useData = useFetchData();
    const screenSize = useScreenSize();
    const networkState = useNetworkState();
    const user = useUser();
    const auth = useAuth();
    const ota = useOTA();
    const route = useRoute();
    let justStarted = true;
    const router = useIonRouter();
    const routeName = computed(() => { return route.name; });
    const isLoadingSpinner = computed(() => store.state.app.isLoadingSpinner)
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    const showBottomNavigation = computed(() => {
      return store.state.app.showBottomNavigation;
    });

    const showAppLink = computed(() => {
      return store.state.app.showAppLinkBanner;
    });

    const isDesktopToolbarForbidden = computed(() => {
      return !(isPlatform("ios") || isPlatform("ipad"));
    });

    const isDev = isDevEnvironment();


    const enabledDevModeLogging = computed(() => store.state.app.enabledDevModeLogging );
    if (!enabledDevModeLogging.value) {
      // useBindConsole();
    }

    console.log("App.vue setup")
    AppLogger.setup(store);

    onMounted(async () => {
      const { identifier } = await Device.getId();
      await networkState.mountMe();
      store.commit("app/setDeviceId", identifier);


      if (isPlatform("mobileweb")) {
        store.commit('app/setShowAppLinkBanner', true)
      }

      screenSize.mountMe();

      const getLocaleFromStorage = "de" // localStorage.getItem('locale') || 'de';
      store && store.commit('app/setLocale', getLocaleFromStorage);
      await CapacitorUpdater.notifyAppReady();
      
      App.addListener('appStateChange', async (state) => {
        if (state.isActive) {
          justStarted = true;
          try {
            await CapacitorUpdater.notifyAppReady();
            console.log("MYLOG notifyAppReady called on resume");
            await checkForUpdates();
          } catch (error: any) {
            Monitoring.chainError('Error during app state change handling:', error);
          }
        }
      });

      console.log("MYLOG notifyAppReady called")
    });


    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
      const slug = event.url.split('.eu').pop()
      console.log('SLUG IS HERE', slug)

      if(slug) {
        router.navigate(slug, 'forward', 'push')
      }
    })

    const checkForUpdates = async () => {
      if (justStarted) {
        justStarted = false;

        if (store.state.app.disabledDevOTA) {
          console.error('!!!Warning!!!');
          console.error('!!!Warning!!!');
          console.error('!!!Warning!!!');
          console.error('!!!Warning!!!! Do not disable ota for production, uat or dev stage as it will loose OTA functionality when deployed!!!! Use it only in local env!!');
          if (environment.ENVIRONMENT !== "local") {
            Monitoring.error("OTA is disabled in production, uat or dev stage");
          }
        }

        try {
          const available = await ota.updateAvailable();
          if (available && !store.state.app.disabledDevOTA) {
            await ota.updateNow();
          }
        } catch (error: any) {
          Monitoring.chainError('Error checking for updates:', error);
        }
      }
    };

    watch(() => store.state.app.welcomeAnimationDone, (n, o) => checkForUpdates(), { immediate: true })
    watch(user.user, (newUser, oldUser) => {
      if(newUser && newUser?.id !== oldUser?.id) {
        initializePushNotifications()
      }
    }, {
      deep: true,
      immediate: true
    })

    onUnmounted(async() => {
      screenSize.unmountMe();
      await networkState.unmountMe();
    });



    return {
      isLoadingSpinner,
      isDev,
      home,
      settings,
      reader,
      showBottomNavigation,
      showAppLink,
      isDesktopToolbarForbidden,
      t,
      user,
      auth,
      isUpdating: ota.isUpdating,
      routeName,
      organisationPrefix,
      mapOutline,
      readerOutline,
      settingsOutline,
      optionsOutline,
      pinOutline,
      listOutline
    };
  },
  async created() {
    const { connect, isSocketConnected } = useBestandsaufnahmeSockets();
    await connect();
    this.fetchAndStoreSurveyTemplates()
    await handleOfflineData();

    watch(isSocketConnected, (newValue, oldValue) => {
    // check explicitly for false to know if a disconnect occured
    if (newValue && oldValue === false) {
      handleOfflineData();
    }
  });
  },
  methods: {
    async fetchAndStoreSurveyTemplates() {
      try {
        if (this?.user?.isLoggedIn?.() && this?.auth?.hasToken?.()) {
          const templates = await useCreateSurvey().fetchTemplates();
          await setCache('cachedSurveyTemplates', JSON.stringify(templates));
        } else {
          // TODO: seems to be handled by doRefresh as well
          console.log('User is not logged in or does not have a valid token, skipping fetching survey templates');
        }
      } catch(err) {
        console.log(err)
      }
    }
  }
});
</script>

<style scoped lang="scss">

@media (min-width: 1024px) {
  .bottomNavigation {
    display: none;
  }
}

@media (max-width: 1024px) {
  .bottomNavigation {
    display: flex;
  }
}


.app-link-shown {
  top: 69px !important;
  height: calc(100% - 69px) !important;
}
.loadingSpinnerContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  &.displaySpinner {
    opacity: 1;
    transition: background-color 200ms ease-in;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: all;
  }

  .spinner-content {
    background: var(--white100);
    padding: 48px;
    border-radius: 16px;

    ion-spinner {
      --color: var(--primary);
      width: 64px;
      height: 64px;
    }
  }
}

.bottomToolbar {
    height: var(--bottomToolbarHeight);
}

</style>

<style lang="scss">

ion-toast.toastStyle {
  --background: var(--white100);
  --color: var(--primary);
  --width: fit-content;
}
</style>