import useAppConfig from '@/composables/useAppConfig';
import useAppInfo from '@/composables/useAppInfo';
import useFetchData from '@/composables/useFetchData';
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { getCache } from '@/store/storage/ionicStorage';
import * as _ from 'lodash';

export async function initHandlers( uStore: any ) {
  const store = uStore || useStore();
  await checkUserProject( store )
  if(_.isEmpty(store.state.app.appConfig)) {
    //First handle app information
    await useAppInfo().initAppHandlers()
    //Then handle appConfig
    await useAppConfig().handleAppConfig()
  }
}

async function checkUserProject( uStore?: any ) {
  const store = uStore || useStore();
  const user = useUser()
  const loggedIn = await user.isLoggedIn()
  const userProject = await getCache('cachedUserProject')

  if(loggedIn && userProject && _.isEmpty(store.state.user.currentUserProject)) {
    await store.commit('user/storeCurrentUserProject', JSON.parse(userProject))
    useFetchData().doRefresh();
  }
}
