<template>
  <div class="flex flex-row items-center justify-end gap-4 mini">
    <div
      v-if="isLocateButtonEnabled"
      class="flex cursor-pointer"
      :title="$t('immobilie.redirectToMap')"
      @click.stop="$emit('locate')"
    >
      <IonIcon
        size="large"
        :icon="locationOutline"
      />
    </div>
    <div
      v-if="isStatusButtonEnabled"
      class="flex cursor-pointer"
      :title="$t('immobilie.updateStatus')"
      @click.stop="$emit('change-status')"
    >
      <IonIcon
        size="large"
        :icon="statusIconState"
      />
    </div>

    <ProfileIcon
      v-if="isPersonButtonEnabled"
      :user="user"
      @change="$emit('change-person')"
    />

    <div
      v-if="isShareButtonEnabled"
      class="flex cursor-pointer"
      @click.stop="$emit('share')"
    >
      <IonIcon
        size="large"
        :icon="shareSocialOutline"
      />
    </div>
    <div
      v-if="isDownloadButtonEnabled"
      :title="$t('downloadSurveyButton.download')"
      class="flex cursor-pointer"
      @click="$emit('download')"
    >
      <IonIcon
        size="large"
        :icon="downloadIconState"
      />
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>
import Immobilie from '@/models/immobilie.model';
import { ImmobilieStatus } from '@/models/immobilie/interfaces/IImmobilie';
import { IonIcon } from '@ionic/vue';
import {
  alertCircleOutline, archiveOutline, arrowDownCircle, arrowDownCircleOutline, checkmarkCircleOutline,
  checkmarkDoneCircleOutline, ellipseOutline, locationOutline, playCircleOutline, shareSocialOutline, timeOutline
} from "ionicons/icons";
import { computed, PropType } from 'vue';
import ProfileIcon from '../Base/ProfileIcon.vue';

const props = defineProps({
    property: {
        type: Object as PropType<Immobilie>,
        required: true
    },
    isLocateButtonEnabled: {
        type: Boolean,
        default: true
    },
    isPersonButtonEnabled: {
        type: Boolean,
        default: true
    },
    isStatusButtonEnabled: {
        type: Boolean,
        default: true
    },
    isShareButtonEnabled: {
        type: Boolean,
        default: true
    },
    isDownloadButtonEnabled: {
        type: Boolean,
        default: false
    },
    propertyState: {
        type: String,
        default: "unknown"
    }
});

defineEmits(['locate', 'change-status', 'change-person', 'share', 'download']);

const statusIconState = computed(() => {
    switch (props.property.status) {
        case ImmobilieStatus.ANGELEGT: return ellipseOutline;
        case ImmobilieStatus.GEPLANT: return timeOutline;
        case ImmobilieStatus.IN_DURCHFUEHRUNG: return playCircleOutline;
        case ImmobilieStatus.ABGESCHLOSSEN: return checkmarkCircleOutline;
        case ImmobilieStatus.FREIGEGEBEN: return checkmarkDoneCircleOutline;
        case ImmobilieStatus.ARCHIVIERT: return archiveOutline;
        default: return alertCircleOutline;
    }
});

const downloadIconState = computed(() => {
    if (props.propertyState === 'OfflineAvailable') {
        return arrowDownCircle;
    } else {
        return arrowDownCircleOutline;
    }
});

const user = computed(() => {
    if(props.property.verwalters.length > 0) {
      return props.property.verwalters[0];
    } else {
      return null;
    }
});

</script>

<style
    scoped
    lang="scss"
></style>