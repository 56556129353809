<template>
  <div>
    <!--
    *
    * First layer: Fragenblocks and 1..N Fragenblocks
    *
    -->


    <!--    <div>-->
    <!--      <h1>DEBUG</h1>-->

    <!--      <div v-for="(f1, i1) in fragenblocks" :key="i1">-->
    <!--        &#45;&#45;&#45;&#45;&#45;&#45;<br />-->
    <!--        {{ f1.getTitle() }}-->

    <!--        <div v-for="(f2, i2) in f1.fragenblocks" :key="i2">-->
    <!--          &#45;&#45;{{ f2 && f2.getTitle() }}-->

    <!--          <div v-for="(f3, i3) in f2.fragenblocks" :key="i3">-->
    <!--            -&#45;&#45;{{ f3 && f3.getTitle() }} {{ f3.config && f3.config.isTemplate }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div
      v-for="(fragenblock, index) in fragenblocks"
      :key="fragenblock.uid ? fragenblock.uid : `outer-${index}`"
    >
      <hzba-group
        v-if="!fragenblock.config?.isTemplate && fragenblock.isShown()"
        :title="fragenblock.getTitle()"
        :suffix="fragenblock.getIndexPosition(parentFragenblock)"
        hide-progress
        :data-cy="`group-`+fragenblock.identifier"
        class="relative"
        :foldable="fragenblock.config?.foldable"
      >
        <div 
          v-if="enabledDevMode"
          class="absolute top-0 right-2 z-10"
          @click="activeDebug = activeDebug === index ? undefined : index"
        >
          o
        </div>
        <DebugFragenblock
          v-if="enabledDevMode && activeDebug === index"
          :fragenblock="fragenblock"
          @close="activeDebug = undefined"
        />

        <!--        :edit-suffix-enabled="fragenblock.blockTyp && fragenblock.blockTyp.toLowerCase() === 'mehrfach'"-->

        <template
          v-if="fragenblock.isInstanceOfMultiple()"
          #cta
        >
          <hzba-group-ctas
            :hide-add-button="true"
            @delete="async () => { 
              await parentFragenblock.deleteFragenblockInstance(fragenblock);
              await updateSurvey();
            }"
            @duplicate="async () => { 
              await parentFragenblock.duplicateFragenblockInstance(fragenblock);
              await updateSurvey();
            }"
          />
        </template>

        <!--
        *
        * Second layer: Here we find questions and (1..n) fragenblocks that directly opens a modal
        *
        -->

        <!-- Questions -->

        <!-- (1..N) Fragenblocks -->
        <div
          v-for="(fragenB, i) in fragenblock.fragenblocks"
          :key="`${i}`"
          class="relative"
        >
          <!-- simple components - click opens modal -->
          <hzba-modal-item-block
            v-if="!fragenB.config?.isTemplate && !fragenB.isInstanceOfMultiple() && fragenB.isShown()"
            :data-cy="`modal-${fragenB.identifier}`"
            :title="fragenB.getTitle()"
            :progress="fragenB.getProgress()"
            :disabled="!fragenB.isUnlocked() || isUpdating"
            :maengel-amount="fragenB.getFiredMaengel().length"
            :errors="fragenB.errors()"
            @click-item="
              () => openHzbaModal(
                'fragenblock',
                { ba, fragenblockBase: fragenB, modalDepth: modalDepth+1 },
                (data) => setDataFromCallback(fragenblock, index, i, data) )
            "
          />

          <!-- 1:N components - click opens modal -->
          <div v-else-if="fragenB.config?.isTemplate">
            <hzba-separator>
              {{ fragenB.getTitle() }}
              <span v-if="fragenB.config?.minCount || fragenB.config?.maxCount">(</span>
              <span v-if="fragenB.config?.minCount">min. {{ fragenB.config.minCount }}</span>
              <span v-if="fragenB.config?.minCount && fragenB.config?.maxCount">,&nbsp;</span>
              <span v-if="fragenB.config?.minCount">max. {{ fragenB.config.maxCount }}</span>
              <span v-if="fragenB.config?.minCount || fragenB.config?.maxCount">)</span>
            </hzba-separator>

            <div>
              <hzba-modal-item-block
                v-for="(multiFragenBlock, multiIndex) in fragenblock.getMultipleFragenblockInstances(fragenB.identifier)"
                :key="multiFragenBlock.uid"
                :data-cy="`modal-${multiFragenBlock.identifier}-${multiIndex}`"
                :title="multiFragenBlock.getTitle()"
                :progress="multiFragenBlock.getProgress()"
                :suffix="`${(multiIndex + 1)}`"
                :disabled="!multiFragenBlock.isUnlocked() || isUpdating"
                :maengel-amount="multiFragenBlock.getFiredMaengel().length"
                :errors="multiFragenBlock.errors()"
                slide-enabled
                @click-item="
                  () => openHzbaModal(
                    'fragenblock',
                    { ba, fragenblockBase: multiFragenBlock, modalDepth: modalDepth + 1},
                    (data) => setDataFromCallback(fragenblock, index, i, data) )
                "
                @delete="async () => {
                  await fragenblock.deleteFragenblockInstance(multiFragenBlock);
                  await updateSurvey();
                }"
                @duplicate="async () => {
                  await fragenblock.duplicateFragenblockInstance(multiFragenBlock);
                  await updateSurvey();
                }"
              />
            </div>

            <hzba-modal-item-block
              v-if="!isReadonly"
              :title="fragenB.getTitle() + ' ' + t('hzba.buttons.hinzufuegen')"
              :data-cy="`modal-${fragenB.identifier}-buttons`"
              add-icon
              :disabled="isUpdating"
              @click-item="async () => {
                await fragenblock.addFragenblockInstance(fragenB);
                await updateSurvey();
              }"
            />
          </div>

          <div
            v-if="enabledDevMode"
            class="absolute top-0 right-2 z-10"
            @click="activeDebug = activeDebug === `sub-${i}` ? undefined : `sub-${i}`"
          >
            o
          </div>
          <DebugFragenblock
            v-if="enabledDevMode && activeDebug === `sub-${i}`"
            :fragenblock="fragenB"
            @close="activeDebug = undefined"
          />
        </div>

        <hzba-form
          :form-frages="fragenblock.frages"
          @input-changed="handleFormInputChange"
        />

        <AdditionalMaengel 
          v-if="fragenblock.config?.customMaengel?.enabled"
          :path="fragenblock.path"
          :mangels="fragenblock.freieMangels"
          :config="fragenblock.config?.customMaengel"
          :fragenblock="fragenblock"
        />
      </hzba-group>

    <!--    <pre>{{ parentFragenblock.toClassJson() }}</pre>-->
    </div>


    <div
      v-if="!isReadonly"
    >
      <div
        v-for="(fragenblock, index) in fragenblocks"
        :key="index"
      >
        <div
          v-if="fragenblock.config?.isTemplate"
          class="boxed-container p-4 text-center"
        >
          <IonButton
            fill="clear"
            data-cy="button-neuer-waermeerzeuger"
            class="normal-case"
            @click="async () => { 
              await parentFragenblock.addFragenblockInstance(fragenblock);
              await updateSurvey();
            } "
          >
            {{ fragenblock.getTitle() + " " + t("hzba.buttons.hinzufuegen").toLowerCase() }}
          </IonButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { putBestandsaufnahme } from "@/api/ModelApi";
import AdditionalMaengel from "@/components/hzba/Base/AdditionalMaengel.vue";
import HzbaForm from "@/components/hzba/Base/HzbaForm.vue";
import HzbaGroupCtas from "@/components/hzba/Base/HzbaGroupCtas.vue";
import DebugFragenblock from "@/components/Other/DebugFragenblock.vue";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import { BestandsaufnahmeJson, HzbaStatusCode } from "@/models/ba/interfaces/IBestandsaufnahme";
import { FragenblockJson } from "@/models/ba/interfaces/IFragenblock";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { openHzbaModal } from "@/utilities/modal-helper";
import { Network } from "@capacitor/network";
import { IonButton } from "@ionic/vue";
import _ from 'lodash';
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import HzbaGroup from "./Base/HzbaGroup";
import HzbaModalItem from "./Base/Input/HzbaModalItem";
import HzbaModalItemBlock from "./Base/Input/HzbaModalItemBlock";
import HzbaSeparator from "./Base/Input/HzbaSeparator";
import { Monitoring } from "@/utilities/monitoring";

export default defineComponent({
  name: "RecursiveForm",
  components: {
    DebugFragenblock,
    AdditionalMaengel,
    HzbaGroupCtas, HzbaForm, HzbaSeparator, HzbaModalItemBlock, HzbaModalItem, HzbaGroup, IonButton, },
  props: {
    parentFragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    },
    modalDepth: {
      type: Number,
      default: -1,
    },
    saveOnFrageInputChanged: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore()
    const { user } = useUser();
    const activeDebug = ref();
    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });
    const fragenblocks = computed(() => {
      return props.parentFragenblock && props.parentFragenblock.fragenblocks
    });
    const currentProject = computed(() => store.state.user.currentUserProject);
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);
    const { updateSurvey, isUpdating } = useBestandsaufnahmeUpload()
    /**
     * Todo does this change have any performance impact? if so, find another solution or reactivate the legacy code
     */
    watch(() => props.parentFragenblock, async (newVal) => {
      // fragenblocks.value?.forEach(el => el.setupFragenblockMinimumInstances())
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupUnlockDetector())
      props.parentFragenblock?.fragenblocks?.forEach(el => el.setupShowDetector())
    }, { immediate: true })
    // watch(() => fragenblocks.value, async (newVal) => {
    //   // fragenblocks.value?.forEach(el => el.setupFragenblockMinimumInstances())
    //   fragenblocks.value?.forEach(el => el.setupUnlockDetector())
    //   fragenblocks.value?.forEach(el => el.setupShowDetector())
    // }, { immediate: true })

    const setDataFromCallback = async (parentFragenblock: Fragenblock, index: number, i: number, callbackData: { action: string; data: FragenblockJson }) => {
      const parentPath = parentFragenblock.path;

      if (callbackData.action === 'DUPLICATE') {
        await parentFragenblock.duplicateFragenblockInstance(new Fragenblock(callbackData.data, parentPath, props.modalDepth));
        await updateSurvey();
      } else if (callbackData.action === 'DELETE') {
        await parentFragenblock.deleteFragenblockInstance(new Fragenblock(callbackData.data, parentPath, props.modalDepth));
        await updateSurvey();
      } 
      // else if (callbackData.action === 'SAVE') {
      //   const ind: number | undefined = parentFragenblock.fragenblocks?.findIndex(el => el.uid === callbackData.data.uid);

      //   if (ind !== undefined && parentPath) {
      //     parentFragenblock.setDirty();
      //     parentFragenblock.fragenblocks![ind] = new Fragenblock(callbackData.data, parentPath, props.modalDepth);
      //   } else {
      //     Monitoring.error('setDataFromCallback caused an error. Index of element not found?', ind, parentPath);
      //   }
      // }

      /**
       * After a modal was saved, we need to refresh the show/unlockdetectors because they might reference to an outdated (and removed) object now.
       */
      console.log("Recursive form after save...");
      fragenblocks.value?.forEach(el => el.setupUnlockDetector())
      fragenblocks.value?.forEach(el => el.setupShowDetector())
    }

    const saveLocally = _.debounce(async () => {
      const oldMalus = ba.value?.malus;
      const oldMalusColor = ba.value?.malusColor; 

      await store.dispatch("currentHzba/saveHzbaLocally");
      // Compare old and new values after the dispatch
      const newMalus = ba.value?.malus;
      const newMalusColor = ba.value?.malusColor;
      const networkStatus = await Network.getStatus();

      if ( networkStatus.connected && ( oldMalusColor !== newMalusColor || oldMalus !== newMalus ) ) {
        console.log("malusColor has changed from", oldMalusColor, "to", newMalusColor);
        const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true});
        // console.log('Copied BA JSON before uploading:', {data: copiedBa});
  
        const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${copiedBa?.id}?projectId=${currentProject.value.id}`,
            {data: copiedBa},
            {save: false},
        );
      }
    }, 500)

    const setBestandsaufnahmeStatus = async () => {
      try {
        if(ba.value?.status === HzbaStatusCode.ANGELEGT || ba.value?.status === HzbaStatusCode.GEPLANT) {
          const isOnline = store.getters["app/isOnline"];
          let updatedBaJson = null;

          if(isOnline) {
            const fieldsToSet: any = {
              status: HzbaStatusCode.IN_DURCHFUEHRUNG
            };
            if(featureFlags?.value.survey?.showSichtungsdatum && !ba.value.sichtungsdatum) {
              fieldsToSet.sichtungsdatum = new Date().toISOString();
            }
            const oldSurvey = await ba.value.toClassJson();
            updatedBaJson = await putBestandsaufnahme(ba.value.id, currentProject.value.id, fieldsToSet, oldSurvey);        
          } else {
            updatedBaJson = await ba.value.toClassJson()
            updatedBaJson.status = HzbaStatusCode.IN_DURCHFUEHRUNG;
            if(featureFlags?.value.survey?.showSichtungsdatum && !ba.value.sichtungsdatum) {
              updatedBaJson.sichtungsdatum = new Date().toISOString();
            }
          }

          await BestandsaufnahmeModel.insertOrUpdate({ data: updatedBaJson });
          const updatedBa = new Bestandsaufnahme(updatedBaJson, undefined);
          updatedBa.isLocal = false; // legacy property
          store.commit('currentHzba/setCurrentBa', updatedBa);
        }
      } catch (error: any) {
        Monitoring.chainError("Error while setting status to IN_DURCHFUEHRUNG", error);
      }
    }

    const handleFormInputChange = () => {
      setBestandsaufnahmeStatus();
      saveLocally();
    }

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const enabledDevMode = computed(() => store.state.app.enabledDevMode );

    return {
      fragenblocks,
      setDataFromCallback,
      t,
      handleFormInputChange,
      isReadonly,
      openHzbaModal,
      activeDebug,
      enabledDevMode,
      updateSurvey,
      isUpdating
    }
  },
})
</script>

<style scoped>

</style>