<template>
  <ion-page class="projectSelectionPage">
    <Toolbar :title="t('userProject.chooseProject')" />
    <ion-content>
      <div class="projectSelectionWrapper container px-4 md:px-0 md:px-4 2xl:px-0">
        <h1 class="text-2xl md:text-3xl lg:mx-auto pt-4">
          {{ user?.organisation.name }}
        </h1>
        <div class="text-2xl md:text-3xl lg:mx-auto">
          {{ t('userProject.chooseProject') }}
        </div>
        <div class="projectsList mt-16">
          <ProjectCard
            v-for="item in user?.organisation.projects"
            :key="item.id"
            :project="item"
            @click="handleProjectSelection(item)"
          />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/useTypedStore";
import { IonContent, IonPage, onIonViewWillEnter, useIonRouter } from '@ionic/vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
//Composables
import useUser from '@/composables/useUser';
//Components
import Toolbar from '@/components/Navigation/Toolbar.vue';
import ProjectCard from '@/components/v2/Project/ProjectCard.vue';
import useFetchData from '@/composables/useFetchData';
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
//Variables
const store = useStore()
const router = useIonRouter()
const { t } = useI18n({ useScope: 'global' })
const user = computed(() => useUser().user.value)
const useData = useFetchData();
//Vuex Mutations
const storeCurrentProject = (project: object) => store.commit('user/storeCurrentUserProject', project)
const currentProject = computed(() => store.state.user.currentUserProject);

//Lifecycle Methods
onIonViewWillEnter(() => {
  store.commit("app/showBottomNavigation");
});

//Methods
async function handleProjectSelection(project: any) {
  const oldProjectId = currentProject.value.id;
  await storeCurrentProject(project);

  if ( oldProjectId !== project.id ) {
    await BestandsaufnahmeModel.dispatch('$deleteAllFromLocal');
    await Immobilie.dispatch('$deleteAllFromLocal');
  }

  useData.doRefresh();
  router.navigate('/', 'forward', 'pop')
}
</script>

<style lang="scss">
.projectSelectionPage {
  .projectSelectionWrapper {
    .projectsList {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
</style>
