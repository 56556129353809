<template>
  <div>
    <ion-header class="ion-padding-horizontal ion-padding-top">
      <slot />
    </ion-header>
    <div :class="styleClasses">
      <ion-content class="ion-padding">
        <div class="flex flex-col h-full">
          <div v-if="selectedProperty">
            <ion-label class="ml-2 text-sm">
              {{ $t("selection") }}
            </ion-label>
            <property-card
              class="mt-0 mx-0 mb-2 rounded-lg"
              :property="selectedProperty"
              :is-compact-view="true"
              :show-subtitle="false"
              :custom-click-function="() => goToDetailPage(selectedProperty)"
            >
              <template #header>
                <ion-button
                  class="h-auto mt-0"
                  fill="clear"
                  @click.stop="goToDetailPage(selectedProperty)"
                >
                  <ion-icon
                    class="w-8 h-8"
                    :title="$t('immobilie.toOpen')"
                    :icon="chevronForwardOutline"
                  />
                </ion-button>
              </template>
            </property-card>
          </div>
          <div 
            class="flex-1 overflow-y-auto"
            @touchmove="handleScrollByTouchEvent($event)"
          >
            <ion-label class="ml-2 text-sm">
              {{ diplaySearchResults }}
            </ion-label>
            <ion-list>
              <PropertyItem
                v-for="property in properties"
                :id="`prop-item-${property.id}`"
                :key="property.id"
                :property="property"
                :selected="selectedPropertyId === property.id"
                @open="open(property)"
              />
            </ion-list>
          </div>
        </div>  
      </ion-content>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useStore } from "@/composables/useTypedStore";
import Immobilie from '@/models/immobilie.model';
import { IonButton, IonContent, IonHeader, IonIcon, IonLabel, IonList, useIonRouter } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import { computed, defineEmits, defineProps, PropType } from 'vue';
import PropertyCard from "./PropertyCard.vue";
import PropertyItem from './PropertyItem.vue';

const props = defineProps({
    properties: {
      type: Object as PropType<Immobilie[]>,
      required: true,
    },
    selectedPropertyId: {
      type: Number,
      required: false,
      default: null
    },
    selectedProperty: {
      type: Object as PropType<Immobilie>,
      required: false,
      default: undefined
    },
    diplaySearchResults: {
      type: String,
      required: true,
    },
    styleClasses: {
      type: String,
      required: true,
      default: "modal-content-container"
    }
});
const emit = defineEmits(["open"]);
const router = useIonRouter()

const store = useStore();
const isMobile = computed(() => {
    return store.getters["app/isMobile"];
});

function open(property: Immobilie) {
  emit("open", property);
}
function handleScrollByTouchEvent(e: TouchEvent){
    if(props.selectedPropertyId !== null) {
    e.stopPropagation();
    }
}

function goToDetailPage(prop: any) {
    store.dispatch("app/setOpenedPropertyId", prop.id);
    if (!isMobile.value) {
        router.push({
            path: `/property/${prop.id}`
        })
    }
}
</script>
<style scoped lang="scss">
.modal-content-container {
    height: 70vh;
    overflow-y: scroll;
}
.desktop-content-container {
    height: 100vh;
    overflow-y: scroll;
}
</style>