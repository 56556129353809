import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';

import { environment } from "../environments/environment";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonicVue } from '@ionic/vue';

// Vuex
import { store, storeTypes, waitForSQLDriver } from '@/store/store';

// Styling
import { i18n } from "@/i18n";
import '@/styles/ionic_variables.css';
import '@/styles/utilities.css';
import Markdown from 'vue3-markdown-it';

import '@/services/error.service';
import { Environments } from './types/app/environment';
import { Monitoring } from './utilities/monitoring';

const init = () => {
  const app = createApp(App)
    .use(IonicVue, {
      backButtonText: '',
    })
    .use(router)
    .use(store, storeTypes)
    .use(i18n)
    .use(Markdown);

  const sentryOptions = {
    app,
    environment: environment.ENVIRONMENT as Environments,
    release: environment.BUILD_VERSION,
    enabled: environment.ENVIRONMENT !== "local"
  };
  Monitoring.initializeSentry(sentryOptions);
  // Monitoring.setTag("debugging", "true"); // comment in when working on Sentry functionalities

  router.isReady().then(() => {
    app.mount('#app');
  });

  // Call the element loader after the platform has been bootstrapped
  defineCustomElements(window);
};

waitForSQLDriver
  .catch((e: any) => Monitoring.chainError(`Failed to set cordova sqlite driver. Using IndexedDb instead.`, e))
  .finally(init);