<template>
  <div
    class="flex personIcon cursor-pointer"
    :title="$t('immobilie.updatePerson')"
    @click.stop="$emit('change')"
  >
    <div v-if="profilbild && isProfilePictureFetched">
      <IonImg
        :src="profilbild"
        @ion-error="isProfilePictureFetched = false"
      />
    </div>
    <div
      v-else
      class="personIcon"
    >
      {{ usernameInitials }}
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
>
import User from '@/models/user';
import { getPhotoUrl } from '@/utilities/get-media-url';
import { IonImg } from '@ionic/vue';
import { computed, PropType, ref } from 'vue';

const props = defineProps({
  user: {
    type: [Object, null, undefined] as PropType<User | null | undefined>,
    required: true
  }
});

defineEmits(['change']);

const isProfilePictureFetched = ref(true);

const profilbild = computed(() => {
  if (!props.user) {
    return false;
  }
  const picture = props.user.profilbild;
  return picture ? getPhotoUrl(picture, { thumbnail: true }) : false;
});

const usernameInitials = computed(() => {
  if (!props.user) {
    return '';
  }
  const name = props.user.username;
  const words = name.split(' ');
  const firstLetters = words.map((word: string) => word[0]);
  return firstLetters.join('');
});

</script>


<style
  scoped
  lang="scss"
></style>