import Immobilie from '@/models/immobilie.model';
import { Monitoring } from '@/utilities/monitoring';
import { Coordinates, GeoJSONSource, LngLatBounds, Map, MapMouseEvent } from 'maplibre-gl';
import { onMounted } from 'vue';
import { MapConfig } from './useAppConfig';

export function usePropertyMap(properties: Immobilie[]) {
  //Variables
  let map = null as any | null; //TODO: use Map type
  let propertySource: any;
  let myPropertyList: Immobilie[] = properties;

  //Lifecycle Methods
  onMounted(() => {
    setPropertySource();
  });

  //Methods
  function setPropertySource(propertyId?: number): void {
    const workItemsGeoJSONPoints = myPropertyList.filter(prop => prop.geolocationLon && prop.geolocationLat).map((property: Immobilie) => {
      if (property.geolocationLat !== null) {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [property.geolocationLon, property.geolocationLat],
          },
          properties: {
            itemData: property.id,
            selected: propertyId === property.id
          },
        };
      }
    });

    propertySource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: workItemsGeoJSONPoints,
      }
    };
  }

  function addPropertySource(): void {
    map?.addSource('propertySource', propertySource)
  }


  function addPropertyLayer(): void {

    // bigger circle, no opacity, for better touch selection
    map.addLayer({
      id: 'higher-tolerance-layer',
      type: 'circle',
      source: 'propertySource',
      paint: {
        'circle-radius': 40,
        'circle-opacity': 0 
      }
    });

    map?.addLayer({
      id: 'property-points',
      type: 'circle',
      source: 'propertySource',
      paint: {
        'circle-radius': 8,
        "circle-stroke-width": 2.5,
        'circle-color': [
          'case',
          ['==', ['get', 'selected'], true], '#BADEF2',
          '#511A3F'
        ],
        "circle-opacity": 0,
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'selected'], true], '#BADEF2', '#511A3F'

        ]
      }
    });
  }

  function getBounds(): LngLatBounds {
    const coordinates: Coordinates = propertySource.data.features.map((feature: any) => {
      return feature.geometry.coordinates;
    });

    const bounds: LngLatBounds = coordinates.reduce((bounds: any, coord: any) => {
      return bounds.extend(coord);
    }, new LngLatBounds(coordinates[0], coordinates[0]));

    return bounds;
  }

  function flyTo(property: Immobilie, options: { zoom?: number, minZoom?: number, speed?: number, essential?: boolean } = {}): void {
    (map as Map)?.jumpTo({
      center: [property.geolocationLon, property.geolocationLat],
      zoom: options.zoom ?? 17.5,
      // minZoom: options.minZoom ?? 13,
      // speed: options.speed ?? 0.8,
      // essential: options.essential ?? true
    });
  }

  function selectProperty(propertyId: number | null): void {
    const source: GeoJSONSource = (map as Map)?.getSource('propertySource') as GeoJSONSource;
    if(propertyId) {
      setPropertySource(propertyId);
    } else {
      setPropertySource();
    }
    source?.setData(propertySource.data);
  }

  async function animateToProperty(property: Immobilie): Promise<void> {
    if (map) {
      selectProperty(property.id);
      flyTo(property, { speed: 0.5 });
    }
  }

  function updateProperties(props: Immobilie[]): void {
    myPropertyList = props;
    setPropertySource();
    if (map) {
      (map.getSource('propertySource') as GeoJSONSource).setData(propertySource.data);
    }
  }

  function reload(): void {
    map?.resize();
  }

  const generateMap = (mapObject: any, mapconfig: any) => {
    map = mapObject;
    addPropertySource();
    addPropertyLayer();
    zoomToOverview(mapconfig);
  }

  const zoomToOverview = (mapconfig: MapConfig) => {
    try {
      if (map) {
        if (propertySource?.data.features.length > 1) {
          map.fitBounds(getBounds(), { padding: 150 });
        } else if (propertySource?.data.features.length === 1 && propertySource?.data.features[0].geometry) {
       
          map.jumpTo({
            center: [propertySource.data.features[0].geometry.coordinates[0], propertySource.data.features[0].geometry.coordinates[1]],
            zoom: 17.5
          });
        } else {
          map.jumpTo({ // initial view
            center: (mapconfig as any)?.mapCenter || mapconfig?.mapStyle?.mapCenter || [16.363449, 48.210033],
            zoom:  (mapconfig as any)?.mapInitialZoomLevel || mapconfig?.mapStyle?.mapInitialZoomLevel || 17
          });
        }
      }
    } catch (e: any) {
      Monitoring.withScope((scope, scrub) => {
        const style = (mapconfig as any)?.style as string ?? mapconfig?.mapStyle?.style ?? null;
        scope.setContext("MapConfig", { "Style" : scrub(style) });
        Monitoring.chainError("Error in zoomToOverview", e);
      });
    }
  }

  const getPropertyByPoint = (point: MapMouseEvent["point"]) => {
    const exactResults = (map as Map)?.queryRenderedFeatures(point, {
      layers: ['property-points'],
    });
    const increasedResults = (map as Map)?.queryRenderedFeatures(point, {
      layers: ['higher-tolerance-layer'],
    });
    if (exactResults?.length > 0 && increasedResults?.length > 1) { 
      return exactResults[0].properties.itemData;
    } else if (increasedResults?.length > 0) {
      return increasedResults[0].properties.itemData;
    }
    return null;
  }

  return {
    animateToProperty,
    flyTo,
    updateProperties,
    reload,
    generateMap,
    zoomToOverview,
    getPropertyByPoint,
    selectProperty
  };
}
