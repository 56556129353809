import { createI18n } from 'vue-i18n';
import messages from './lang';

const getLocaleFromStorage = localStorage.getItem('locale') || 'de';

export const i18n = createI18n({
  globalInstall: true,
  locale: 'de', // FIXED FOR "de"
  messages,
  globalInjection: true,
  legacy: false,
});


export default { i18n };